<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 class="m-0 text-light">
                {{ t('pages.more.aboutApp.aboutApp') }}
            </h1>
        </div>
        <ul class="list-group shadow-sm">
            <li
                @click="router.push('/more/about-app/tos')"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                <div>
                    <span style="font-size: 1.08rem">{{
                        t('pages.more.aboutApp.tos')
                    }}</span>
                    <div class="text-muted">
                        {{ t('pages.more.aboutApp.tosDescription') }}
                    </div>
                </div>
                <i class="fa fa-chevron-right text-muted"></i>
            </li>
            <li
                @click="router.push('/more/about-app/osl')"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                <div>
                    <span style="font-size: 1.08rem">{{
                        t('pages.more.aboutApp.osl')
                    }}</span>
                    <div class="text-muted">
                        {{ t('pages.more.aboutApp.oslDescription') }}
                    </div>
                </div>
                <i class="fa fa-chevron-right text-muted"></i>
            </li>
            <li class="list-group-item">
                <span style="font-size: 1.08rem">{{
                    t('pages.more.aboutApp.version')
                }}</span>
                <div class="text-muted">
                    {{ version }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import BackButton from '@/components/BackButton';
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const router = useRouter();
const { t } = useI18n();
const version = process.env.VUE_APP_VERSION;
</script>
